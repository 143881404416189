// extracted by mini-css-extract-plugin
export var galleryMasonryImageWrapper = "q_jQ d_jQ d_cv";
export var galleryMasonryImage = "q_jP d_jP d_w d_bR d_dB";
export var alignLeft = "q_qM d_fp d_bG d_dv";
export var alignCenter = "q_bP d_fq d_bD d_dw";
export var alignRight = "q_qQ d_fr d_bH d_dx";
export var galleryContainer = "q_rx d_dW";
export var galleryContainerFull = "q_ry d_dT";
export var galleryRowWrapper = "q_rz d_cc";
export var galleryGuttersImage = "q_jS d_jS d_K d_cv";
export var galleryNoGuttersImage = "q_jR d_jR d_K d_cD";
export var galleryTextGutters = "q_jM d_jM d_cw";
export var galleryTextNoGutters = "q_jN d_jN d_cw";
export var galleryTextMasonry = "q_rB d_jM d_cw";
export var galleryImageWrapper = "q_rC d_fg d_Z";
export var descText = "q_rD d_jT d_0 d_9 d_7 d_8 d_n";
export var guttersDesc = "q_rF";