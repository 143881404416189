// extracted by mini-css-extract-plugin
export var lbContainerOuter = "z_vn";
export var lbContainerInner = "z_vp";
export var movingForwards = "z_vq";
export var movingForwardsOther = "z_vr";
export var movingBackwards = "z_vs";
export var movingBackwardsOther = "z_vt";
export var lbImage = "z_vv";
export var lbOtherImage = "z_vw";
export var prevButton = "z_vx";
export var nextButton = "z_vy";
export var closing = "z_vz";
export var appear = "z_vB";