// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "p_rd d_gv d_cs";
export var heroHeaderCenter = "p_gw d_gw d_cs d_dw";
export var heroHeaderRight = "p_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "p_rf d_gr d_cw";
export var heroParagraphCenter = "p_gs d_gs d_cw d_dw";
export var heroParagraphRight = "p_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "p_rg d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "p_rh d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "p_rj d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "p_rk d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "p_rl d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "p_rm s_rm";
export var heroExceptionNormal = "p_rn s_rn";
export var heroExceptionLarge = "p_rp s_rp";
export var Title1Small = "p_rq s_rq s_rH s_rJ";
export var Title1Normal = "p_rr s_rr s_rH s_rK";
export var Title1Large = "p_rs s_rs s_rH s_rL";
export var BodySmall = "p_rt s_rt s_rH s_r0";
export var BodyNormal = "p_rv s_rv s_rH s_r1";
export var BodyLarge = "p_rw s_rw s_rH s_r2";